<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="6">
          <mf-text-input class="mx-2" label="Nome" :value="type" disabled />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.quantity"
            class="mx-2"
            :rules="[notEmptyRule, notNegativeRule]"
            label="Quantidade de lojas"
            type="number"
            outlined
            value
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { productsTotalValue } from '../../../mixin/products'
export default {
  mixins: [productsTotalValue],
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: '',
      product: this.value,
      totalValue: 0,
      notEmptyRule: v => !!v || 'Campo obrigatório',
      notNegativeRule: v => !(v < 0) || 'O valor não pode ser negativo'
    }
  }
}
</script>

<style></style>
