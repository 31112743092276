<template>
  <v-dialog v-model="dialog" persistent width="900">
    <v-card class="pa-2">
      <v-card-title class="px-4">Inserir novo produto</v-card-title>
      <v-card-text class="px-0">
        <v-card-subtitle class="pb-1">{{ cardSubtitle || 'Selecione o produto:' }}</v-card-subtitle>
        <v-row no-gutters>
          <v-col>
            <v-autocomplete v-model="selected" outlined class="mx-4" :items="availableProducts" item-text="name" item-value="value" label="Produto" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selected" class="mt-n5 px-2">
        <v-form ref="form" v-model="valid" lazy-validation style="background-color: none">
          <cbm-form v-if="selected === 'cbm'" v-model="newProduct" :type="selectedProductName"></cbm-form>
          <app-form v-if="selected === 'app'" v-model="newProduct" :type="selectedProductName"></app-form>
          <mercashop-form v-if="selected === 'mercashop'" v-model="newProduct" :type="selectedProductName"></mercashop-form>
          <whats-form v-if="selected === 'whatsapp'" v-model="newProduct" :type="selectedProductName"></whats-form>
          <mercapromo-form v-if="selected === 'mercapromo'" v-model="newProduct" :type="selectedProductName"></mercapromo-form>
          <promotrade-form v-if="selected === 'promotrade'" v-model="newProduct" :type="selectedProductName"></promotrade-form>
          <email-form v-if="selected === 'email'" v-model="newProduct" :type="selectedProductName"></email-form>
          <leader-form v-if="selected === 'mercalider'" v-model="newProduct" :type="selectedProductName"></leader-form>
          <sms-form v-if="selected === 'sms'" v-model="newProduct" :type="selectedProductName"></sms-form>
          <head-count-form v-if="selected === 'headcount'" v-model="newProduct" type="Recurso Dedicado"></head-count-form>
          <infrastructure-form v-if="selected === 'infrastructure'" v-model="newProduct" type="Infraestrutura"></infrastructure-form>
          <customization-form v-if="selected === 'customization'" v-model="newProduct" type="Customizações"></customization-form>
          <v-row v-if="showSelectUnit" class="mt-n7 px-2">
            <v-col>
              <v-autocomplete
                v-model="unitsSelect"
                :rules="[notEmptyRule, ruleValidateUnits]"
                :items="unitsForSelect"
                multiple
                label="Lojas"
                placeholder="Buscar"
                item-text="displayName"
                return-object
                outlined
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0 && unitsSelect.length === 1">{{ item.name }}</span>
                  <span v-if="index === 0 && unitsSelect.length > 1">{{ unitsSelect.length }} selecionados</span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mx-2 pt-0">
        <v-spacer />
        <mf-action-buttons
          class="mr-1"
          :primary-button="{
            text: 'Inserir',
            action: pushProduct,
            isVisible: true,
            isDisabled: !selected
          }"
          :extra-button="{
            text: 'Cancelar',
            action: closeModal,
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Component used to insert new products into a sale (new_sale and upsell)
 * @component
 */

import _ from 'lodash'
import CbmForm from '../molecules/CbmForm.vue'
import AppForm from '../molecules/AppForm.vue'
import MercashopForm from '../molecules/MercashopForm.vue'
import EmailForm from '../molecules/EmailForm.vue'
import LeaderForm from '../molecules/LeaderForm.vue'
import MercapromoForm from '../molecules/MercapromoForm.vue'
import PromotradeForm from '../molecules/PromotradeForm.vue'
import WhatsForm from '../molecules/WhatsForm.vue'
import SmsForm from '../molecules/SmsForm.vue'
import HeadCountForm from '../molecules/HeadCountForm.vue'
import InfrastructureForm from '../molecules/InfrastructureForm.vue'
import CustomizationForm from '../molecules/CustomizationForm.vue'
import { ProductsById } from '@/constants'
import cnpj from '@/filters/cnpj'
export default {
  emits: ['close'],
  components: {
    CbmForm,
    AppForm,
    MercashopForm,
    EmailForm,
    LeaderForm,
    MercapromoForm,
    PromotradeForm,
    WhatsForm,
    SmsForm,
    HeadCountForm,
    InfrastructureForm,
    CustomizationForm
  },
  props: {
    /**
     * @model
     */
    dialog: {
      type: Boolean,
      default: () => false
    },
    /**
     * Type of product available
     * @values ['app', 'cbm', 'mercashop', 'email', 'sms', 'whatsapp', 'mercapromo', 'head-count']
     */
    availableProducts: {
      type: Array,
      default: () => []
    },
    /**
     * Products included in the sale
     */
    products: {
      type: Array,
      default: () => []
    },
    /**
     * Subtitle of the modal
     */
    cardSubtitle: {
      type: String,
      default: () => ''
    },
    /**
     * Show units input
     */
    showUnitsInput: {
      type: Boolean,
      default: false
    },
    /**
     * Units included in the sale
     */
    clientUnits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      newProduct: {
        name: '',
        type: '',
        unit_value: null,
        quantity: null,
        total_value: 0,
        implantation_value: null,
        has_order: false,
        is_accumulative: false,
        charge_type: '',
        immediate_billing: false,
        billing_date: '',
        app_type: '',
        plan: ''
      },
      unitsSelect: [],
      notEmptyRule: v => v.length >= 1 || 'Campo obrigatório',
      selected: '',
      valid: false,
      immediate_billing_items: ['5', '10', '15', '20', '25'],
      app_types: [
        {
          value: 'phygital',
          label: 'Phygital'
        },
        {
          value: 'ecommerce',
          label: 'E-commerce'
        },
        {
          value: 'club',
          label: 'Clube'
        }
      ]
    }
  },
  computed: {
    selectedProductName() {
      return ProductsById[this.selected]?.name || this.firstLetter(this.selected)
    },
    showSelectUnit() {
      return this.showUnitsInput && ProductsById[this.selected]?.hasUnits
    },
    clientUnitsSelectable() {
      const unitsCnpj = this.unitsSelect.map(i => i.cnpj)
      return this.clientUnits.filter(item => unitsCnpj.includes(item?.cnpj.replace(/[^0-9]/g, ''))).map(item => item._id)
    },
    unitsForSelect() {
      const currentSaleUnitsWithSelectedProduct =
        this.products?.filter(product => product.type === this.selected)?.reduce((acc, product) => [...acc, ...(product?.client_units ?? [])], []) ?? []
      return this.clientUnits.map(unit => ({
        ...unit,
        displayName: `${cnpj(unit.cnpj)} - ${unit.name}`,
        disabled: unit[this.selected] || currentSaleUnitsWithSelectedProduct.includes(unit._id)
      }))
    }
  },
  watch: {
    selected(value) {
      this.newProduct.type = value
      this.newProduct.name = this.getProductFromType(value).name
    }
  },
  mounted() {
    this.newProduct.downsell = this.$route.query['sales'] === 'downsell'
  },
  methods: {
    ruleValidateUnits(value) {
      if (this.showSelectUnit && value.length !== Number(this.newProduct.quantity)) {
        return 'A quantidade de lojas selecionadas não corresponde à quantidade de lojas informada.'
      }
      return true
    },
    getProductFromType(productType) {
      return ProductsById[productType] || {}
    },
    /**
     * Clears the current selected product, resets all inputs and closes the modal
     */
    closeModal() {
      this.selected = ''
      this.cleanFields()
      this.$emit('close')
    },
    /**
     * Format string to uppercase first letter
     * @param {String} string
     * @returns Value formatted with the first letter capitalized
     */
    firstLetter(val) {
      return val.charAt(0).toUpperCase() + val.slice(1)
    },
    /**
     * Configures and validates the product that will be included in the sale with the correct properties
     */
    setupProduct() {
      const productType = this.newProduct.type
      return Object.assign(
        {},
        { name: this.newProduct.name },
        { type: this.newProduct.type },
        { quantity: +this.newProduct.quantity },
        { downsell: {} },
        { billing_date_periods: this.newProduct.billing_date },
        { immediate_billing: this.newProduct.immediate_billing },
        { total_value: +this.newProduct.total_value },
        { unit_value: +this.newProduct.unit_value },
        this.showSelectUnit ? { units: this.unitsSelect.map(i => i.cnpj).join(',') } : null,
        this.showSelectUnit ? { client_unit_ids: this.clientUnitsSelectable } : null,
        productType === 'app' ? { app_type: this.newProduct.app_type } : null,
        productType === 'sms' || productType === 'email' || productType === 'mercalider' || productType === 'customization'
          ? { charge_type: this.newProduct.charge_type }
          : null,
        productType === 'mercalider' ? { plan: this.newProduct.plan } : null,
        productType === 'sms' || productType === 'email' ? { is_accumulative: Boolean(this.newProduct.is_accumulative) } : null,
        this.getProductFromType(productType).hasSetupCost ? { implantation_value: +this.newProduct.implantation_value } : null
      )
    },
    /**
     * Validates, configures and updates the array of products that will be inserted in the sale, then closes the modal
     */
    pushProduct() {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })

      const product = this.setupProduct()
      this.products.push(_.omitBy(_.cloneDeep(product), _.isUndefined))
      this.$emit('update:products', this.products)
      this.$alert({
        alert_message: `Produto inserido com sucesso`,
        alert_title: 'Sucesso!',
        alert_color: 'success',
        alert_icon: 'mdi-check-circle'
      })

      setTimeout(() => {
        this.closeModal()
      }, 100)
    },
    /**
     * Resets newProduct
     */
    cleanFields() {
      this.newProduct.name = ''
      this.newProduct.type = ''
      this.newProduct.unit_value = null
      this.newProduct.quantity = null
      this.newProduct.total_value = 0
      this.newProduct.downsell = this.$route.query['sales'] === 'downsell'
      this.newProduct.implantation_value = null
      this.newProduct.has_order = false
      this.newProduct.is_accumulative = false
      this.newProduct.charge_type = ''
      this.newProduct.billing_date = ''
      this.newProduct.immediate_billing = false
      this.newProduct.app_type = ''
      this.newProduct.plan = ''
      this.unitsSelect = []
    },
    getDate() {
      return this.$moment().format('YYYY-MM-DD')
    }
  }
}
</script>
