<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="6">
          <mf-text-input class="mx-2" label="Nome" :value="type" disabled />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.total_value"
            :rules="[notEmptyRule, notNegativeRule]"
            class="mx-2"
            label="Valor total estimado"
            type="number"
            suffix="R$"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.percentage_value_of_cost"
            :rules="[notEmptyRule, notNegativeRule]"
            class="mx-2"
            label="Valor percentual do custo"
            type="number"
            suffix="%"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <mf-select
            v-model="product.charge_type"
            :rules="[notEmptyRule]"
            :items="chargeTypes"
            :item-text="'label'"
            :item-value="'value'"
            class="mx-2"
            label="Tipo de cobrança"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { productsUnitValue } from '../../../mixin/products'
export default {
  mixins: [productsUnitValue],
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: '',
      product: {},
      chargeTypes: [
        {
          value: 'recurring',
          label: 'Recorrente'
        },
        {
          value: 'single',
          label: 'Única'
        }
      ],
      notEmptyRule: v => !!v || 'Campo obrigatório',
      notNegativeRule: v => !(v < 0) || 'O valor não pode ser negativo'
    }
  },
  mounted() {
    this.product = {
      ...this.value,
      quantity: 1
    }
  }
}
</script>
