<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="6">
          <mf-text-input class="mx-2" label="Nome" :value="type" disabled />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.unit_value"
            class="mx-2"
            :rules="[notEmptyRule, notNegativeRule]"
            label="Valor unitário"
            type="number"
            suffix="R$"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.quantity"
            class="mx-2"
            :rules="[notEmptyRule, notNegativeRule]"
            label="Quantidade de lojas"
            type="number"
            outlined
            value
          />
        </v-col>
        <v-col cols="6">
          <v-text-field :value="totalValue" class="mx-2" label="Valor total" type="number" suffix="R$" outlined readonly />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.implantation_value"
            class="mx-2"
            :rules="[notEmptyRule, notNegativeRule]"
            label="Valor da implantação"
            type="number"
            suffix="R$"
            outlined
          />
        </v-col>
        <v-col cols="2">
          <mf-toggle v-model="product.immediate_billing" class="mx-2" label="Faturamento imediato" color="#334A58" value />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="product.billing_date"
            :items="immediateBillingItems"
            class="mx-2"
            :disabled="!product.immediate_billing"
            label="Data de vencimento"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { productsTotalValue } from '../../../mixin/products'
export default {
  mixins: [productsTotalValue],
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: '',
      product: this.value,
      immediateBillingItems: ['5', '10', '15', '20', '25'],
      notEmptyRule: v => !!v || 'Campo obrigatório',
      notNegativeRule: v => !(v < 0) || 'O valor não pode ser negativo'
    }
  },
  computed: {
    totalValue() {
      if (this.product.quantity && this.product.unit_value) {
        const value = this.product.quantity * this.product.unit_value
        return value.toFixed(2)
      }
      return 0
    }
  }
}
</script>
<style></style>
