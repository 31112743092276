<template>
  <v-row>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="6">
          <mf-text-input class="mx-2" label="Nome" :value="type" disabled />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="product.total_value"
            :rules="[notEmptyRule, notNegativeRule]"
            class="mx-2"
            label="Valor total"
            type="number"
            suffix="R$"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="product.quantity" :rules="[notEmptyRule, notNegativeRule]" class="mx-2" label="Quantidade" type="number" outlined value />
        </v-col>
        <v-col cols="6">
          <mf-toggle v-model="product.immediate_billing" class="mx-2" label="Faturamento imediato" color="#334A58" value />
        </v-col>
        <v-col cols="6">
          <mf-select
            v-model="product.billing_date"
            :items="immediateBillingItems"
            :rules="product.immediate_billing ? [notEmptyRule] : []"
            class="mx-2"
            :disabled="!product.immediate_billing"
            label="Data de vencimento"
          ></mf-select>
        </v-col>
        <v-col cols="6">
          <mf-select
            v-model="product.charge_type"
            :rules="[notEmptyRule]"
            :items="chargeTypes"
            :item-text="'label'"
            :item-value="'value'"
            class="mx-2"
            label="Tipo de cobrança"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { productsUnitValue } from '../../../mixin/products'
export default {
  mixins: [productsUnitValue],
  props: {
    value: {
      type: Object
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: '',
      product: this.value,
      immediateBillingItems: ['5', '10', '15', '20', '25'],
      chargeTypes: [
        {
          value: 'recurring',
          label: 'Recorrente'
        },
        {
          value: 'single',
          label: 'Única'
        }
      ],
      notEmptyRule: v => !!v || 'Campo obrigatório',
      notNegativeRule: v => !(v < 0) || 'O valor não pode ser negativo'
    }
  },
  computed: {
    unitValue() {
      if (this.product.quantity && this.product.total_value && this.product.quantity !== '0' && this.product.total_value !== '0') {
        const value = this.product.total_value / this.product.quantity
        return value.toFixed(2)
      }
      return 0
    }
  }
}
</script>
